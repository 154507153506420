/* eslint-disable radar/cognitive-complexity */
import {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useCallback,
} from 'react';

import { HTMLMotionProps } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { BiCertification } from 'react-icons/bi';
import { useTheme } from 'styled-components';

import { api } from '@services';
import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';
import { Button } from '@components';
import { useAuth, useEventfy } from '@hooks';
import { selectUser } from '@hooks/useAuth/selectors';

import * as S from './styles';

type CertificateModalBaseProps = HTMLMotionProps<'dialog'>;

type CertificateModalProps = PropsWithChildren<CertificateModalBaseProps>;

const CertificateModal = (
  props: CertificateModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const theme = useTheme();
  const event = useEventfy();
  const { name } = useAuth(selectUser);
  const { t } = useTranslation();

  const { children, ...attrs } = props;

  const handleCertificate1 = useCallback(async () => {
    const response = await api.get<Blob>('/user/certificate1', {
      responseType: 'blob',
    });

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Cert 08-02 ${name} - ${event.name}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode?.removeChild(link);
  }, [name, event.name]);

  const handleCertificate2 = useCallback(async () => {
    const response = await api.get<Blob>('/user/certificate2', {
      responseType: 'blob',
    });

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Cert 22-02 ${name} - ${event.name}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode?.removeChild(link);
  }, [name, event.name]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCertificate = useCallback(async () => {
    const response = await api.get<Blob>('/user/certificate', {
      responseType: 'blob',
    });

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Cert 15-03 ${name} - ${event.name}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode?.removeChild(link);
  }, [name, event.name]);

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}

      <h2>{t('modal.certificate.title')}</h2>

      <S.Wrapper>
        <div>
          {event.image_certificate ? (
            <img
              src={event.image_certificate}
              alt={t('modal.certificate.title')}
            />
          ) : (
            <BiCertification size="8rem" color={theme.colors.success[100]} />
          )}

          <p>{t('modal.certificate.subTitle')}</p>
          <small style={{ width: '75%', color: theme.colors.primary[100] }}>
            Para acessar os seus certificados de participação, insira as
            palavras-chaves divulgadas durante as lives. Não se esqueça: elas
            são indispensáveis!
          </small>
          <Button
            title="Baixar Certificado - Dia 08/02"
            onClick={handleCertificate1}
          />
          <Button
            title="Baixar Certificado - Dia 22/02"
            onClick={handleCertificate2}
          />
          <Button
            title="Baixar Certificado - Dia 15/03"
            onClick={handleCertificate}
          />
        </div>
      </S.Wrapper>
    </S.Container>
  );
};

export default forwardRef(CertificateModal);
