import styled, { css } from 'styled-components';
import { down } from 'styled-breakpoints';
import { motion } from 'framer-motion';

import { PerfectScrollbar } from '@components';

export const Container = styled(motion.dialog)`
  ${({ theme }) => css`
    padding: 2.5rem 2rem;
    margin: auto;
    width: 100%;
    max-width: 60rem;
    height: 100%;
    max-height: 60rem;
    border-radius: 1rem;
    position: relative;
    background: ${theme.colors.background[100]};
    color: ${theme.colors.foreground[100]};

    box-shadow: 0px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0px 6.7px 5.3px rgba(0, 0, 0, 0.028), 0px 12.5px 10px rgba(0, 0, 0, 0.035),
      0px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0px 41.8px 33.4px rgba(0, 0, 0, 0.05), 0px 100px 80px rgba(0, 0, 0, 0.07);

    > h2 {
      padding-bottom: 0.5rem;
      border-bottom: 2px solid ${theme.colors.background[80]};
    }

    ${down('md')} {
      padding: 2.5rem 1.25rem;
    }
  `}
`;

export const SurveyWrapper = styled(PerfectScrollbar)`
  ${({ theme }) => css`
    padding-top: 1rem;
    height: 100%;
    max-height: calc(100% - 2.5rem);

    .sv_main {
      height: 100%;

      * {
        box-sizing: border-box;
        min-width: 0;
      }

      span {
        word-break: break-word;
        white-space: pre-wrap;
      }

      > form {
        height: 100%;

        .sv_container {
          height: 100%;

          > div {
            height: 100%;
          }

          .sv_q_rating {
            fieldset {
              margin: 0 !important;

              .sv_q_rating_item_text {
                padding: 0 2em !important;
              }

              .sv_q_rating_item:nth-child(2)
                .sv_q_rating_item_text:not(:hover) {
                border-left-color: #e7e7e7 !important;
              }
            }
          }

          .sv_body {
            padding: 0;
            border: 0;
            height: 100%;
            display: flex;
            flex-direction: column;

            .sv_nav {
              margin-top: auto;
            }

            .sv_p_root {
              > .sv_row {
                border: 0;
                min-width: auto;

                > div {
                  min-width: auto !important;
                }
              }

              .sv_q {
                padding: 0.5em 0 1.5em;
              }

              .sv_q_erbox {
                border-radius: 0.5rem;
              }

              .sv_q_title {
                font-weight: 500;
                font-size: 1.25rem;
                color: ${theme.colors.foreground[100]};
              }

              .sv_q_description {
                margin-bottom: 1rem;
              }

              .sv-q-col-1 {
                padding: 0;

                & + .sv-q-col-1 {
                  margin-top: 0.5rem;
                }

                .sv_q_radiogroup_label,
                .sv_q_checkbox_label {
                  padding: 0;
                  cursor: pointer;

                  span:not(:last-child) {
                    height: 0;
                    display: none;
                  }

                  input {
                    opacity: 0;
                    height: 0;
                    width: 0;
                    display: none;

                    & ~ span:last-child {
                      display: block;
                      background: ${theme.colors.background[80]};
                      border: 1px solid ${theme.colors.background[60]};
                      padding: 0.5rem 0.75rem;
                      border-radius: 0.5rem;
                      transition: 180ms ease-in-out;
                    }

                    &:checked ~ span:last-child {
                      background: ${theme.colors.success[100]};
                      font-weight: 500;
                    }
                  }

                  :hover {
                    > span:last-child {
                      background: ${theme.colors.success[100]};
                    }
                  }
                }
              }

              input[type='text'] {
                border-radius: 0.5rem;
                padding: 0.5rem 1rem !important;
                height: initial !important;

                :focus {
                  border: 1px solid ${theme.colors.brand.primary.default} !important;
                }
              }
            }

            .sv_progress_bar {
              margin-top: auto;
              background: ${theme.colors.brand.primary.default};
              transition: 180ms ease-in-out;
            }

            input[type='button'],
            .sv_main button {
              background: ${theme.colors.brand.primary.default};
              font-size: 1rem;
              padding: 0.5rem 1rem;
              border-radius: 0.5rem;
              transition: 180ms ease-in-out;

              :hover {
                filter: brightness(1.25);
              }

              :active {
                filter: brightness(0.75);
              }
            }
          }
        }
      }
    }
  `}
`;

export const FinishedSurveyWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
`;
