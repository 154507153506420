/* eslint-disable radar/cognitive-complexity */
import {
  useState,
  DispatchWithoutAction,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';

import * as Survey from 'survey-react';
import { HTMLMotionProps } from 'framer-motion';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { BiCertification } from 'react-icons/bi';
import { useTheme } from 'styled-components';

import { SurveyQuestionType } from '@common/types/pusher/events';
import { SatisfactionSurveyAnswersTypes } from '@common/types/api/satisfaction-survey';
import { api } from '@services';
import {
  useAuth,
  useEventfy,
  useIsCertificateEnabled,
  useSatisfactionSurveyQuery,
} from '@hooks';
import {
  selectUser,
  selectUserHasRepliedSurvey,
  selectUpdateProfile,
} from '@hooks/useAuth/selectors';
import { DEFAULT_MODAL_ANIMATION, DEFAULT_TRANSITION } from '@constants';
import { Button } from '@components';

import * as S from './styles';

type SurveyModalBaseProps = HTMLMotionProps<'dialog'> & {
  handleClose: DispatchWithoutAction;
  handleCertificate: DispatchWithoutAction;
};

type SurveyModalProps = PropsWithChildren<SurveyModalBaseProps>;

const SurveyModal = (
  props: SurveyModalProps,
  ref: ForwardedRef<HTMLDialogElement>,
) => {
  const theme = useTheme();
  const event = useEventfy();
  const { name } = useAuth(selectUser);
  const isCertificateEnabled = useIsCertificateEnabled();

  const updateProfile = useAuth(selectUpdateProfile);
  const userHasRepliedSurvey = useAuth(selectUserHasRepliedSurvey);

  const satisfactionSurveyQuery = useSatisfactionSurveyQuery();

  const [userHasJustAnsweredSurvey, setUserHasJustAnsweredSurvey] =
    useState(false);

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const { children, handleClose, ...attrs } = props;

  const surveyModel = useMemo(
    () =>
      new Survey.Model({
        locale: 'pt-br',
        showTitle: false,
        questionsOnPageMode: 'questionPerPage',
        showProgressBar: 'top',
        progressBarType: 'questions',
        goNextPageAutomatic: true,
        pages: [
          {
            elements: satisfactionSurveyQuery.data?.map((question, index) => {
              const title = question.asking;

              const baseData = {
                name: `${question.id}-question`,
                title,
                valueName: `${index}`,
                description: question.description,
                isRequired: question.required_answer,
                requiredErrorText: 'Resposta obrigatória',
              };

              if (question.asking_type === SurveyQuestionType.SINGLE_CHOICE) {
                return {
                  ...baseData,
                  type: 'radiogroup',
                  choices: question.answers.data.map((answer) => ({
                    value: answer.id,
                    text: {
                      'pt-br': answer.answer,
                    },
                  })),
                };
              }

              if (question.asking_type === SurveyQuestionType.MULTIPLE_CHOICE) {
                return {
                  ...baseData,
                  type: 'checkbox',
                  choices: question.answers.data.map(({ answer }) => answer),
                };
              }

              if (question.asking_type === SurveyQuestionType.RATING) {
                return {
                  ...baseData,
                  type: 'rating',
                  rateMin: question.rateMin,
                  rateMax: question.rateMax,
                  minRateDescription: question.minRateDescription,
                  maxRateDescription: question.maxRateDescription,
                };
              }

              return {
                ...baseData,
                type: 'text',
                placeholder: 'Escreva suas sugestões',
              };
            }),
          },
        ],
      }),
    [satisfactionSurveyQuery.data],
  );

  const handleSurveyCompletion = useCallback(
    async ({ valuesHash: results }: Survey.ReactSurveyModel) => {
      if (!satisfactionSurveyQuery?.data) return;

      const surveyPayload = Object.entries(satisfactionSurveyQuery.data).reduce(
        (acc, [index, question]) => {
          const key = +index;

          const answer = results[key];
          const isSingleChoice =
            question.asking_type === SurveyQuestionType.SINGLE_CHOICE;

          const isMultipleChoice =
            question.asking_type === SurveyQuestionType.MULTIPLE_CHOICE;

          if (isSingleChoice) {
            acc.pesquisa.push({
              survey_asking_id: question.id,
              asking_type: SurveyQuestionType.SINGLE_CHOICE,
              answer: answer || '',
            });
          } else if (isMultipleChoice) {
            acc.pesquisa.push({
              survey_asking_id: question.id,
              asking_type: SurveyQuestionType.MULTIPLE_CHOICE,
              answer: (answer as string[]) || [],
            });
          } else {
            acc.pesquisa.push({
              survey_asking_id: question.id,
              asking_type: SurveyQuestionType.TEXT,
              answer: answer || '',
            });
          }

          return acc;
        },
        { pesquisa: [] as SatisfactionSurveyAnswersTypes[] },
      );

      try {
        const { data: response } = await api.post<{ message: string }>(
          '/survey',
          surveyPayload,
        );

        toast.success(response.message);
        updateProfile({ survey_replied: 1 });
        setUserHasJustAnsweredSurvey(true);
      } catch (err) {
        const fallbackMessage =
          'Ocorreu um erro ao tentar enviar a sua resposta, tente novamente';

        const message =
          (err as AxiosError<{ message: string }>).response?.data.message ??
          fallbackMessage;
        toast.error(message);
      }
    },
    [satisfactionSurveyQuery.data, updateProfile],
  );

  const handleCertificate1Click = useCallback(async () => {
    setIsLoading1(true);

    const response = await api.get<Blob>('/user/certificate1', {
      responseType: 'blob',
    });

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Cert 08-02 ${name} - ${event.name}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    setIsLoading1(false);
    // 5. Clean up and remove the link
    link.parentNode?.removeChild(link);
    handleClose();
  }, [name, event.name, handleClose, setIsLoading1]);

  const handleCertificate2Click = useCallback(async () => {
    setIsLoading2(true);

    const response = await api.get<Blob>('/user/certificate2', {
      responseType: 'blob',
    });

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Cert 22-02 ${name} - ${event.name}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    setIsLoading2(false);
    // 5. Clean up and remove the link
    link.parentNode?.removeChild(link);
    handleClose();
  }, [name, event.name, handleClose, setIsLoading2]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCertificateClick = useCallback(async () => {
    setIsLoading(true);

    const response = await api.get<Blob>('/user/certificate', {
      responseType: 'blob',
    });

    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Cert 15-03 ${name} - ${event.name}.pdf`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    setIsLoading(false);
    // 5. Clean up and remove the link
    link.parentNode?.removeChild(link);
    handleClose();
  }, [name, event.name, handleClose, setIsLoading]);

  return (
    <S.Container
      open
      ref={ref}
      transition={DEFAULT_TRANSITION}
      variants={DEFAULT_MODAL_ANIMATION}
      {...attrs}
    >
      {children}

      <h2>Pesquisa de Satisfação</h2>

      {!userHasRepliedSurvey && satisfactionSurveyQuery.isSuccess && (
        <S.SurveyWrapper>
          <Survey.Survey
            model={surveyModel}
            onComplete={handleSurveyCompletion}
          />
        </S.SurveyWrapper>
      )}

      {userHasRepliedSurvey && (
        <S.FinishedSurveyWrapper>
          <div>
            <BiCertification size="8rem" color={theme.colors.success[100]} />
            <p>{`${
              userHasJustAnsweredSurvey
                ? 'Obrigado por responder'
                : 'Você já respondeu'
            } à nossa pesquisa de satisfação`}</p>

            {isCertificateEnabled && (
              <>
                <p>Clique no botão abaixo para acessar o certificado:</p>
                <small
                  style={{ width: '75%', color: theme.colors.primary[100] }}
                >
                  Para acessar os seus certificados de participação, insira as
                  palavras-chaves divulgadas durante as lives. Não se esqueça:
                  elas são indispensáveis!
                </small>
                <Button
                  title="Baixar Certificado - Dia 08/02"
                  loading={isLoading1}
                  onClick={handleCertificate1Click}
                />
                <Button
                  title="Baixar Certificado - Dia 22/02"
                  loading={isLoading2}
                  onClick={handleCertificate2Click}
                />
                <Button
                  title="Baixar Certificado - Dia 15/03"
                  loading={isLoading}
                  onClick={handleCertificateClick}
                />
              </>
            )}
          </div>
        </S.FinishedSurveyWrapper>
      )}
    </S.Container>
  );
};

export default forwardRef(SurveyModal);
